/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from 'react';
import Navbar from '../Components/Navbar';
// import ProgressBar from '../Components/Progress_bar/ProgressBar';
import calendar from '../assets/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {useNavigate} from 'react-router-dom';
import Search from '../assets/Search.svg';
import {useDispatch, useSelector} from 'react-redux';
import {
  setCountData,
  setDBName,
  setDataType,
  setDeviceSelected,
  setFromDate,
  setToDate,
} from '../redux/Features/DataSlice';
import {
  getDatabaseList,
  getDeviceStats,
  getMarkers,
} from '../Mock_Backend/server';
import millify from 'millify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from 'react-toastify';
import DatabaseIcon from '../assets/database.svg';
import Dropdown from 'react-dropdown';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';

const Data = [
  {
    items: [],
  },
  {
    items: [],
  },
  {items: []},
  {
    items: [],
  },
];

const styles = {
  // ...other styles...
  valueContainer: (css) => ({
    ...css,
    'flex-wrap': 'nowrap',
    'white-space': 'nowrap',
    'overflow': 'hidden',
    'text-overflow': 'ellipsis',
  }),
};

/**
 * @return {Component} Job Details => selecting date , devices etc..
 */
function DateSelection() {
  // const [clickAll, setCA] = React.useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  console.log(selectedDevices);
  const [dataValue, setDataValue] = useState('a');
  const handleChange = (event) => {
    setDataValue(event.target.value);
  };
  const [value, setValue] = useState([]);
  console.log(value);
  const selectRef = useRef();
  // const getAllDevicesData = async () => {
  //   Data[0].items = [];
  //   Data[1].items = [];
  //   Data[2].items = [];
  //   Data[3].items = [];
  //   let str='';
  //   if (!backend) {
  //     const getdata = await getAllDevices();
  //     getdata.map((item) => {
  //       str = str + item.device + ',';
  //     });
  //   } else {
  //     countData?.map((item) => {
  //       if (/^[M]\d/.test(item.collection)||/^[S]\d/.test(item.collection)) {
  //         str = str + item.collection + ',';
  //       }
  //       console.log(str);
  //     });
  //   }
  //   str = str.substring(0, str.length - 1);
  //   console.log(str);
  //   const x = validateConvert(str);
  //   console.log(x);
  //   inputRef.current.value = x;
  //   setSelectedOptions(x);
  //   setColor(false);
  // };
  // const getAllMobileDevicesData = async () => {
  //   Data[0].items = [];
  //   Data[1].items = [];
  //   Data[2].items = [];
  //   Data[3].items = [];
  //   let str='';
  //   if (!backend) {
  //     const getdata = await getAllDevices();
  //     getdata.map((item) => {
  //       str = str + item.device + ',';
  //     });
  //   } else {
  //     countData?.map((item) => {
  //       if (/^[M]\d/.test(item.collection)) {
  //         str = str + item.collection + ',';
  //       }
  //       console.log(str);
  //     });
  //   }
  //   str = str.substring(0, str.length - 1);
  //   console.log(str);
  //   const x = validateConvert(str);
  //   console.log(x);
  //   inputRef.current.value = x;
  //   setSelectedOptions(x);
  //   setColor(false);
  // };
  const getAllDevices = async () => {
    setAllDevices(false);
    let str = '';
    if (!backend) {
      const getdata = await getAllDevices();
      getdata.map((item) => {
        str = str + item.device + ',';
      });
    } else {
      countData?.map((item) => {
        str = str + item.collection + ',';
        console.log(str);
      });
    }
    str = str.substring(0, str.length - 1);
    console.log(str);
    const x = validateConvertForAllDevices(str);
    console.log(x);
    // setSelectedOptions(x);
    // setColor(false);
  };
  // const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setoptions] = useState([]);
  const fromDate = useSelector((state) => state.data.newRequest.from);
  const dbName = useSelector((state) => state.data.newRequest.dbName);
  const toDate = useSelector((state) => state.data.newRequest.to);
  const countData = useSelector((state) => state.data.countData);
  const showNotify = () =>
    toast('Select the Database and From and To Dates...');
  const [colourOptions, setColorOptions] = useState();
  const backend = useSelector((state) => state.data.backend);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Option, setOption] = useState({value: dbName});
  console.log(fromDate.length);
  const [startDate, setStartDate] = useState(
    fromDate.length > 0 ? new Date(fromDate) : '',
  );
  const [endDate, setEndDate] = useState(
    toDate.length > 0 ? new Date(toDate) : '',
  );
  // const [markers, setMarkers] = useState();
  const [devices, setDevices] = useState();
  // const [filteredDevices,setFilteredDevices]=useState();
  const [devicesArr, setDevicesArr] = useState();
  console.log(devicesArr);
  const [allDevices, setAllDevices] = useState(false);
  const validateConvert = (data) => {
    data = data.toUpperCase();
    data.replace(' ', '');
    const arr = data.split(',');
    let res = '';
    // let filteredArr = [];
    const devicesArray = [];
    for (const d of arr) {
      if (d.includes('-')) {
        const temp = d.split('-');
        const [x, y] = temp;
        console.log(x, y);
        const start = x.split(/(\d.*)/).filter(Boolean);
        const end = y.split(/(\d.*)/).filter(Boolean);
        console.log(start);
        console.log(end);
        for (let i = start[1]; i <= end[1]; i++) {
          res += start[0] + i + ',';
          devicesArray.push(start[0] + i + 'S');
        }
      } else {
        res += d + ',';
        devicesArray.push(d + 'S');
      }
      console.log(res);
      console.log(devicesArray);
      setDevicesArr(devicesArray);
    }
  };
  const validateConvertForAllDevices = (data) => {
    data = data.toUpperCase();
    data.replace(' ', '');
    const arr = data.split(',');
    let res = '';
    // let filteredArr = [];
    const devicesArray = [];
    for (const d of arr) {
      if (d.includes('-')) {
        const temp = d.split('-');
        const [x, y] = temp;
        console.log(x, y);
        const start = x.split(/(\d.*)/).filter(Boolean);
        const end = y.split(/(\d.*)/).filter(Boolean);
        console.log(start);
        console.log(end);
        for (let i = start[1]; i <= end[1]; i++) {
          res += start[0] + i + ',';
          devicesArray.push(start[0] + i);
        }
      } else {
        res += d + ',';
        devicesArray.push(d);
      }
      console.log(res);
      console.log(devicesArray);
      setDevicesArr(devicesArray);
    }
    // const selectedDevices = [];
    devicesArray?.map((item) => {
      setValue((value) => [...value, {value: item, label: item}]);
      setSelectedDevices((arr) => [...arr, item]);
    });
  };
  console.log(selectedDevices);
  const getData = async () => {
    setAllDevices(true);
    if (!backend) {
      const deviceStats = await getDeviceStats();
      console.log(deviceStats);
      setDevices(deviceStats);
    } else {
      console.log('main implementation');
      const st = Date.parse(startDate);
      const et = Date.parse(endDate);
      console.log(Option);
      const token = JSON.parse(sessionStorage.getItem('token'));
      const deviceStats = await axios.get(
          `https://bw02.kaatru.org/meta/dbs/${Option.value}/cols/ts?st=${st}&et=${et}`,
          {
            headers: {
              'x-caas-token': token,
            },
          },
      );
      dispatch(setCountData(deviceStats.data.data));
      const markersArr = [];
      deviceStats.data.data.map((item) => {
        if (
          item.geo.length > 0 &&
          item.geo[0].lat !== undefined &&
          item.geo[0].lon !== undefined
        ) {
          markersArr.push([item.geo[0]?.lat, item.geo[0]?.lon]);
        }
      });
      console.log(markersArr);
      // setMarkers(markersArr);
      const deviceOptions = [];
      deviceStats.data.data.map((item) => {
        deviceOptions.push({value: item.collection, label: item.collection});
      });
      console.log(deviceOptions);
      setColorOptions(deviceOptions);
      const obj = [];
      deviceStats.data.data.map((item) => {
        obj.push({device: item.collection, count: item.count});
      });
      setDevices(obj);
      setIsLoading(false);
    }
  };
  const setDB = (e) => {
    dispatch(setDBName(e.value));
    setOption(e);
  };
  useEffect(() => {
    // Mock Backend
    const getData = async () => {
      if (!backend) {
        const data = await getMarkers();
        const dbList = await getDatabaseList();
        dbList.map((item) => {
          setoptions((options) => [...options, item.dbname]);
        });
        const markersArr = [];
        data.map((item) => {
          markersArr.push([item.value.lat, item.value.long]);
        });
        // console.log(markersArr);
        // setMarkers(markersArr);
      } else {
        setAllDevices(true);
        console.log(countData);
        if (countData?.length > 0) {
          const deviceOptions = [];
          countData.map((item) => {
            console.log(item.device);
            deviceOptions.push({
              value: item.collection,
              label: item.collection,
            });
          });
          console.log(deviceOptions);
          setColorOptions(deviceOptions);
          const obj = [];
          countData.map((item) => {
            obj.push({device: item.collection, count: item.count});
          });
          setDevices(obj);
        }
        // const data = await getMarkers();
        console.log(JSON.parse(sessionStorage.getItem('token')));
        const token = JSON.parse(sessionStorage.getItem('token'));
        console.log(token);
        // axios.defaults.headers.common={token:"token"}
        const dbList = await axios.get('https://bw02.kaatru.org/meta/dbs', {
          headers: {
            'x-caas-token': token,
          },
        });
        console.log(dbList.data.data);
        dbList.data.data.map((item) => {
          setoptions((options) => [...options, item]);
        });
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center">
      <Navbar />
      {/* <ProgressBar /> */}
      <div className="w-screen flex flex-row items-center justify-center ml-[0%] mt-[10%]">
        <div className="basis-[10%] text-center">
          <p className="font-semibold">Step 1</p>
        </div>
        <div className="basis-4/5 flex-auto p-4 bg-[#B5FFB4] rounded-tl-lg rounded-bl-lg">
          <p className="font-semibold">Select a Data Range</p>
        </div>
      </div>
      <div className="w-[85vw] flex-auto flex flex-row justify-center items-center ml-[15%] mr-[10%] mt-[2%] gap-5">
        <div className="basis-1/3 h-full flex flex-col justify-between">
          <div className="w-full flex justify-end"></div>
          <div className="basis-4/5 flex-auto flex flex-row justify-start items-center gap-8">
            <label htmlFor="" className="font-semibold">
              Database:
            </label>
            <div className="w-[20vw] flex flex-row justify-center items-center border-2 pl-2 rounded-lg bg-[#eeeeee]">
              <img src={DatabaseIcon} alt="" width={'20vmin'} />
              <div className="w-[2px] h-8 bg-stone-500 ml-4"></div>
              <Dropdown
                options={options}
                onChange={(e) => {
                  setDB(e);
                  // setIsCheck(false);
                }}
                value={Option.value}
                placeholder="Select an option"
                className="flex-auto"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-4 mb-4">
            <p className="w-auto flex flex-row justify-start font-[600]">
              Data:
            </p>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={dataValue}
              onChange={handleChange}
              className="flex-nowrap"
            >
              <FormControlLabel
                value="a"
                control={<Radio />}
                label="Accelerometer"
              />
              <FormControlLabel value="s" control={<Radio />} label="Sensor" />
              <FormControlLabel value="b" control={<Radio />} label="Both" />
            </RadioGroup>
          </div>
          <div className="flex flex-col gap-4">
            <div className="">
              <label htmlFor="" className="font-bold">
                From Date
              </label>
              <div className="flex flex-row-reverse border-2">
                <img src={calendar} alt="" className="mr-[5%]" />
                <DatePicker
                  selected={startDate}
                  showTimeInput
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  onChange={(date) => {
                    setStartDate(date);
                    dispatch(setFromDate(date.toString()));
                    // setIsCheck(false);
                  }}
                  className="w-full flex-auto p-2 focus:outline-none "
                  placeholderText="DD/MM/YYYY"
                />
              </div>
            </div>
            <div className="">
              <label htmlFor="" className="font-bold">
                To Date
              </label>
              <div className="flex flex-row-reverse border-2">
                <img src={calendar} alt="" className="mr-[5%]" />
                <DatePicker
                  selected={endDate}
                  minDate={startDate}
                  dateFormat="dd/MM/yyyy"
                  showTimeInput
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => {
                    date.setHours(23, 59, 59, 999);
                    setEndDate(date);
                    dispatch(setToDate(date.toString()));
                  }}
                  className="w-full flex-auto p-2 focus:outline-none "
                  placeholderText="DD/MM/YYYY"
                />
              </div>
            </div>
            <div className="mt-4 w-full flex justify-end">
              <button
                className="px-4 py-2 bg-[#323B4B] text-white rounded-lg"
                onClick={() => {
                  console.log(startDate);
                  if (
                    startDate !== undefined &&
                    endDate !== undefined &&
                    startDate.toString().length > 0 &&
                    endDate.toString().length > 0
                  ) {
                    setIsLoading(true);
                    getData();
                    // setIsCheck(true);
                  } else {
                    showNotify();
                  }
                }}
              >
                Check
              </button>
            </div>
          </div>
        </div>
        <div className="basis-2/3 bg-[#F2F5FB] h-[50vh] rounded-lg flex flex-col justify-center items-center">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row justify-center items-center gap-4 basis-4/6">
              {/* <div
                style={{
                  backgroundColor:
                    clickAll && !(clickAllm || clickAlls) ? '#F2FFA0' : '',
                }}
                onClick={() => {
                  if (!clickAll) {
                    setCAM(false);
                    setCAS(false);
                  }
                  getAllDevicesData();
                  setCA(!clickAll);
                }}
                className="bg-[#eeeeee] p-3 rounded-lg cursor-pointer"
              >
                Select all devices{' '}
              </div> */}
              {/* <div
                style={{backgroundColor: clickAllm ? '#F2FFA0' : ''}}
                onClick={() => {
                  if (!clickAllm) {
                    setCA(false);
                    setCAS(false);
                  }
                  getAllMobileDevicesData();
                  setCAM(!clickAllm);
                }}
                onMouseOver={() => {
                  setMstate(true);
                }}
                onMouseOut={() => {
                  setMstate(false);
                }}
                className="bg-[#eeeeee] p-3 rounded-lg cursor-pointer"
              >
                Select all Mobile{' '}
              </div> */}
            </div>
          </div>
          <div className="basis-[10%]">
            <h2 className="text-xl mt-[4%]">Device Data Count</h2>
          </div>
          <div className="flex w-full justify-start px-[2%] py-4">
            <div className="w-full flex flex-row justify-between items-center gap-4">
              <div className="flex justify-start items-center border-2 px-3 py-1 rounded-full bg-[#EEEEEE]">
                <img src={Search} alt="" width={'20vmin'} className="" />
                <input
                  style={{
                    color: 'black',
                    height: '30px',
                    borderColor: 'black',
                    fontSize: '20px',
                    borderRadius: '5px',
                  }}
                  placeholder="Search Devices"
                  onChange={(data) => {
                    const len = data.target.value.length;
                    Data[0].items = [];
                    Data[1].items = [];
                    Data[2].items = [];
                    Data[3].items = [];
                    if (len > 0) {
                      setAllDevices(false);
                      const x = validateConvert(data.target.value);
                      console.log(x);
                    } else if (len === 0) {
                      setAllDevices(true);
                      Data[0].items = [];
                      Data[1].items = [];
                      Data[2].items = [];
                      Data[3].items = [];
                    }
                  }}
                  className="border-none focus:outline-none px-4 bg-[#eeeeee]" /* options={optionList}*/
                />
              </div>
              <div className="flex-auto w-[20vw]">
                <Select
                  // defaultValue={[colourOptions[2], colourOptions[3]]}
                  ref={selectRef}
                  isMulti
                  name="colors"
                  options={colourOptions}
                  styles={styles}
                  className="basic-multi-select hidden"
                  classNamePrefix="select"
                  value={value}
                  onChange={(e) => setValue(e)}
                  placeholder="Select"
                />
                <div
                  style={{backgroundColor: '#F2FFA0'}}
                  onClick={() => {
                    getAllDevices();
                  }}
                  className="bg-[#eeeeee] p-3 rounded-lg cursor-pointer w-[50%]"
                >
                  Select All Devices{' '}
                </div>
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="w-full h-full flex justify-center items-center">
              <Box sx={{display: 'flex'}}>
                <CircularProgress />
              </Box>
            </div>
          )}
          {!isLoading && (
            <div className="flex-auto w-[100%] grid lg:grid-cols-5 2xl:grid-cols-6 h-full px-[5%] gap-y-2 overflow-auto">
              {allDevices &&
                devices?.map((item) => {
                  return (
                    <div
                      className="h-24 bg-[#323b4b] rounded-lg flex justify-center items-center flex-col text-white mr-2"
                      key={item.device}
                      onClick={() => {
                        setValue((value) => [
                          ...value,
                          {value: item.device, label: item.device},
                        ]);
                        setSelectedDevices((arr) => [...arr, item.device]);
                      }}
                    >
                      <div className="flex flex-col justify-center items-start">
                        <p className="text-left mr-8">{item.device}</p>
                        <p className="text-xl font-bold">
                          {millify(item.count)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {!allDevices &&
                devices?.map((item) =>
                  devicesArr?.map((item1) => {
                    if (item.device === item1) {
                      return (
                        <div
                          className={`h-24 rounded-lg flex justify-center items-center flex-col mr-2 ${
                            selectedDevices.includes(item.device) ?
                              'bg-[#b5feb4] text-black' :
                              'bg-[#000000] text-white'
                          }`}
                          key={item.device}
                          onClick={() => {
                            setValue((value) => [
                              ...value,
                              {value: item.device, label: item.device},
                            ]);
                            setSelectedDevices((arr) => [...arr, item.device]);
                          }}
                        >
                          <div
                            className={`flex flex-col justify-center items-start`}
                          >
                            <p className="text-left mr-8">
                              {item.device.slice(0, -1)}
                            </p>
                            <p className="text-xl font-bold">
                              {millify(item.count)}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  }),
                )}
            </div>
          )}
        </div>
      </div>
      <div className="w-[80%] flex flex-row justify-between items-center mb-[2%] mt-[2%]">
        <button
          className="bg-[#DFDFDF] px-4 py-2 text-[#616161] font-semibold rounded-lg"
          onClick={() => navigate('/select-datasource')}
        >
          Back
        </button>
        <button
          className="bg-[#323B4B] px-4 py-2 text-white font-semibold rounded-lg"
          onClick={() => {
            if (
              fromDate.toString().length > 0 &&
              toDate.toString().length > 0 &&
              Option !== undefined
            ) {
              getData();
              let str = '';
              value.map((item) => {
                str = str + item.value.slice(0, -1) + ',';
              });
              str = str.substring(0, str.length - 1);
              console.log('the value of ', str);
              dispatch(setDeviceSelected(str));
              dispatch(setDataType(dataValue));
              navigate('/select-datatype');
            } else {
              showNotify();
            }
          }}
        >
          Continue
        </button>
      </div>
      <ToastContainer position="top-right" closeOnClick autoClose={false} />
    </div>
  );
}

export default DateSelection;

{
  /* <div className="h-24 bg-[#323b4b] rounded-lg flex justify-center items-center flex-col text-white mr-2" key={item.device}>
<div className="flex flex-col justify-center items-start">
  <p className="text-left mr-8">{item.device}</p>
  <p className="text-xl font-bold">{millify(item.Count)}</p>
</div>
</div> */
}
