export const deviceCount=[
  {
    'device': 'S22',
    'count': 93904,
  },
  {
    'device': 'S11',
    'count': 29384,
  },
  {
    'device': 'S9',
    'count': 929091,
  },
  {
    'device': 'S12',
    'count': 92390,
  },
  {
    'device': 'M2',
    'count': 2934,
  },
  {
    'device': 'M1',
    'count': 1923,
  },
  {
    'device': 'M5',
    'count': 9234,
  },
  {
    'device': 'RM22',
    'count': 4959,
  },
  {
    'device': 'M8',
    'count': 2349,
  },
  {
    'device': 'LM1',
    'count': 93452,
  },
  {
    'device': 'LM2',
    'count': 9485,
  },
  {
    'device': 'RM1',
    'count': 5823,
  },
  {
    'device': 'RM2',
    'count': 5000,
  },
  {
    'device': 'RM3',
    'count': 3000,
  },
  {
    'device': 'M5',
    'count': 105934,
  },
  {
    'device': 'M10',
    'count': 9500,
  },
];
