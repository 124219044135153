/* eslint-disable max-len */
export const response = [{
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13590',
  },
  'device': 'S22',
  'srvtime': {
    '$numberLong': '1679566846203',
  },
  'value': {
    'pID': 94191,
    'temp': 32.22,
    'rh': 68.08,
    'lat': 13.211813,
    'long': 80.317178,
    'location': 'Ennore',
    'ax': 1676,
    'ay': 1872,
    'az': 15776,
    'gx': -534,
    'gy': 350,
    'gz': -74,
    'D_PM1': 13,
    'D_PM2_5': 15,
    'D_PM10': 18,
    'dID': 'LM13',
    'sPM1': 29.9,
    'sPM10': 19.9,
    'dPM1': 28,
    'dPM2': 39,
    'dPM10': 30,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/LM13/1679566846203_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13591',
  },
  'srvtime': {
    '$numberLong': '1679566846473',
  },
  'device': 'S24',
  'value': {
    'pID': 116780,
    'temp': 32.19,
    'rh': 68.42,
    'lat': 13.119072,
    'long': 80.291994,
    'location': 'Royapuram',
    'ax': -508,
    'ay': 280,
    'az': 28052,
    'gx': -694,
    'gy': 141,
    'gz': -84,
    'D_PM1': 17,
    'D_PM2_5': 19,
    'D_PM10': 26,
    'dID': 'LM34',
    'sPM1': 19.9,
    'sPM10': 15.9,
    'dPM1': 30,
    'dPM2': 24,
    'dPM10': 20,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/LM34/1679566846473_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13592',
  },
  'srvtime': {
    '$numberLong': '1679566846633',
  },
  'device': 'S26',
  'value': {
    'pID': 9043,
    'dID': 'S26',
    'rHeap': 131420,
    'lHeap': 110736,
    'dTS': 1679547046,
    'dUT': 753,
    'lat': 13.166123,
    'nso': 'N',
    'long': 80.309076,
    'location': 'Thiruvottiyur',
    'ewo': 'E',
    'alt': 0,
    'sog': 0,
    'cog': 0,
    'hdop': 0,
    'vdop': 0,
    'pdop': 0,
    'age': 0,
    'temp': 32.47,
    'dPM1': 35,
    'dPM2': 22,
    'dPM10': 26,
    'rh': 67.51,
    'sPM1': 9.9,
    'sPM2': 10.4,
    'sPM4': 10.4,
    'sPM10': 10.4,
    'sNPMp5': 66.5,
    'sNPM1': 77.6,
    'sNPM2': 77.8,
    'sNPM4': 77.8,
    'sNPM10': 77.9,
    'sTPS': 0.4,
    'gIR': 65535,
    'gLUM': 0,
    'gUV': 0,
    'pIDar': 11318,
    'aUT': 754,
    'ax': 572,
    'ay': 15904,
    'az': 5052,
    'gx': -220,
    'gy': 92,
    'gz': 48,
    'accTemp': 33.09,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/undefined/1679566846633_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13593',
  },
  'srvtime': {
    '$numberLong': '1679566846655',
  },
  'device': 'S34',
  'value': {
    'pID': 8483,
    'dID': 'S38',
    'rHeap': 130804,
    'lHeap': 113004,
    'dTS': 1679547046,
    'dUT': 707,
    'lat': 13.182042,
    'nso': 'N',
    'long': 80.269788,
    'location': 'Manali',
    'ewo': 'E',
    'alt': 0,
    'sog': 0,
    'cog': 0,
    'hdop': 99.99,
    'vdop': 0,
    'pdop': 0,
    'age': 0,
    'temp': 33.29,
    'rh': 64.38,
    'sPM1': 9.5,
    'sPM2': 9.9,
    'sPM4': 9.9,
    'sPM10': 9.9,
    'sNPMp5': 63.5,
    'sNPM1': 74.1,
    'sNPM2': 74.3,
    'sNPM4': 74.3,
    'sNPM10': 74.3,
    'sTPS': 0.4,
    'dPM1': 24,
    'dPM2': 28,
    'dPM10': 23,
    'gIR': 65535,
    'gLUM': 0,
    'gUV': 0,
    'pIDar': 10595,
    'aUT': 707,
    'ax': 964,
    'ay': 16040,
    'az': -3108,
    'gx': 485,
    'gy': 278,
    'gz': -135,
    'accTemp': 28.53,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/undefined/1679566846655_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13594',
  },
  'srvtime': {
    '$numberLong': '1679566846690',
  },
  'device': 'S44',
  'value': {
    'pID': 8507,
    'dID': 'S36',
    'rHeap': 131132,
    'lHeap': 108340,
    'dTS': 1679547046,
    'dUT': 709,
    'lat': 13.116696,
    'nso': 'N',
    'long': 80.231477,
    'location': 'Perambur',
    'ewo': 'E',
    'alt': 0,
    'sog': 4.83,
    'cog': 223746,
    'hdop': 0,
    'vdop': 0,
    'pdop': 0,
    'age': 6,
    'temp': 33.19,
    'rh': 64.59,
    'sPM1': 9.7,
    'sPM2': 10.2,
    'sPM4': 10.2,
    'sPM10': 10.2,
    'sNPMp5': 64.8,
    'sNPM1': 75.8,
    'sNPM2': 76,
    'sNPM4': 76,
    'sNPM10': 76.1,
    'sTPS': 0.39,
    'dPM1': 12,
    'dPM2': 19,
    'dPM10': 21,
    'gIR': 65535,
    'gLUM': 0,
    'gUV': 0,
    'pIDar': 10625,
    'aUT': 709,
    'ax': 600,
    'ay': 16264,
    'az': -380,
    'gx': -338,
    'gy': 169,
    'gz': 1583,
    'accTemp': 33.8,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/undefined/1679566846690_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13595',
  },
  'srvtime': {
    '$numberLong': '1679566846693',
  },
  'device': 'LM24',
  'value': {
    'pID': 95192,
    'temp': 31.72,
    'rh': 70.06,
    'lat': 13.127093,
    'long': 80.208295,
    'location': 'Kolathur',
    'ax': -2860,
    'ay': 1720,
    'az': 15672,
    'gx': -155,
    'gy': -298,
    'gz': 26,
    'D_PM1': 14,
    'D_PM2_5': 16,
    'D_PM10': 22,
    'dID': 'LM28',
    'sPM10': 19.9,
    'dPM1': 22,
    'dPM2': 23,
    'dPM10': 27,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/LM28/1679566846693_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13596',
  },
  'srvtime': {
    '$numberLong': '1679566846717',
  },
  'device': 'S30',
  'value': {
    'pID': 8723,
    'dID': 'S30',
    'rHeap': 130908,
    'lHeap': 106032,
    'dTS': 1679547046,
    'dUT': 727,
    'lat': 13.116161,
    'nso': 'N',
    'long': 80.263078,
    'location': 'Vyasarpadi',
    'ewo': 'E',
    'alt': 48.5,
    'sog': 1.81,
    'cog': 90.6,
    'hdop': 6.57,
    'vdop': 0,
    'pdop': 0,
    'age': 171,
    'temp': 32.59,
    'rh': 67.24,
    'sPM1': 9.1,
    'sPM2': 10.2,
    'sPM4': 10.6,
    'sPM10': 10.9,
    'sNPMp5': 59.9,
    'sNPM1': 71,
    'sNPM2': 71.8,
    'sNPM4': 72,
    'sNPM10': 72,
    'sTPS': 0.48,
    'dPM1': 12,
    'dPM2': 18,
    'dPM10': 19,
    'gIR': 65535,
    'gLUM': 0,
    'gUV': 0,
    'pIDar': 10895,
    'aUT': 727,
    'ax': 12,
    'ay': 15848,
    'az': -3412,
    'gx': -437,
    'gy': 478,
    'gz': -112,
    'accTemp': 32.95,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/undefined/1679566846717_undefined',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13597',
  },
  'srvtime': {
    '$numberLong': '1679566846938',
  },
  'device': 'RM32',
  'value': {
    'pId': 118038,
    'dPM1': 20,
    'dPM2': 22,
    'dPM10': 25,
    'temp': 31.61,
    'rh': 70.96,
    'dID': 'RM20',
    'lat': 13.099872,
    'long': 80.260105,
    'location': 'Pattalam',
    'sPM10': 19.9,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/RM20/1679566846938_118038',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13598',
  },
  'srvtime': {
    '$numberLong': '1679566846973',
  },
  'device': 'RM40',
  'value': {
    'pId': 117830,
    'temp': 32.32,
    'dPM1': 24,
    'dPM2': 24,
    'dPM10': 22,
    'rh': 69.52,
    'dID': 'RM15',
    'lat': 13.133080,
    'long': 80.278219,
    'location': 'Old Washermanpet',
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/RM15/1679566846973_117830',
}, {
  '_id': {
    '$oid': '641bdaa6da5aa052c7b13599',
  },
  'srvtime': {
    '$numberLong': '1679566846986',
  },
  'device': 'L12',
  'value': {
    'pId': 117827,
    'dPM1': 26,
    'dPM2': 22,
    'dPM10': 23,
    'temp': 31.77,
    'rh': 70.33,
    'dID': 'RM19',
    'lat': 13.092869,
    'long': 80.258723,
    'location': 'Purasavakkam',
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/RM19/1679566846986_117827',
}, {
  '_id': {
    '$oid': '641bdaa7da5aa052c7b1359a',
  },
  'srvtime': {
    '$numberLong': '1679566847103',
  },
  'device': 'L14',
  'value': {
    'pId': 118036,
    'temp': 31.52,
    'rh': 71.23,
    'dPM1': 19,
    'dPM2': 21,
    'dPM10': 17,
    'dID': 'RM21',
    'lat': 13.258255,
    'long': 80.337314,
    'location': 'Puzhithivakkam',
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/RM21/1679566847103_118036',
}, {
  '_id': {
    '$oid': '641bdaa7da5aa052c7b1359b',
  },
  'srvtime': {
    '$numberLong': '1679566847140',
  },
  'device': 'L19',
  'value': {
    'pID': 8279,
    'dID': 'S45',
    'rHeap': 131020,
    'lHeap': 109228,
    'dTS': 1679547047,
    'dUT': 690,
    'lat': 13.169322,
    'long': 80.245455,
    'location': 'Maathur',
    'nso': 'N',
    'ewo': 'E',
    'alt': 0,
    'sog': 0,
    'cog': 0,
    'hdop': 99.99,
    'vdop': 0,
    'pdop': 0,
    'age': 0,
    'temp': 32.91,
    'rh': 66.35,
    'sPM1': 9.9,
    'sPM2': 10.4,
    'sPM4': 10.4,
    'sPM10': 10.4,
    'sNPMp5': 66.2,
    'sNPM1': 77.3,
    'sNPM2': 77.6,
    'sNPM4': 77.6,
    'sNPM10': 77.6,
    'sTPS': 0.39,
    'dPM1': 23,
    'dPM2': 28,
    'dPM10': 30,
    'gIR': 65535,
    'gLUM': 0,
    'gUV': 0,
    'pIDar': 10341,
    'aUT': 690,
    'ax': -2556,
    'ay': 15640,
    'az': -22152,
    'gx': -45,
    'gy': -1239,
    'gz': -693,
    'accTemp': 32.29,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/undefined/1679566847140_undefined',
}, {
  '_id': {
    '$oid': '641bdaa7da5aa052c7b1359c',
  },
  'srvtime': {
    '$numberLong': '1679566847237',
  },
  'device': 'M12',
  'value': {
    'pID': 8346,
    'dID': 'S41',
    'rHeap': 130892,
    'lHeap': 110648,
    'dTS': 1679547047,
    'dUT': 695,
    'lat': 13.190933,
    'long': 80.269622,
    'location': 'Elanthanoor',
    'nso': 'N',
    'ewo': 'E',
    'alt': 0,
    'sog': 0,
    'cog': 0,
    'hdop': 99.99,
    'vdop': 0,
    'pdop': 0,
    'age': 0,
    'temp': 38,
    'rh': 30,
    'sPM1': null,
    'sPM2': null,
    'sPM4': null,
    'sPM10': null,
    'sNPMp5': null,
    'sNPM1': null,
    'sNPM2': null,
    'sNPM4': null,
    'sNPM10': null,
    'sTPS': null,
    'dPM1': 14,
    'dPM2': 18,
    'dPM10': 19,
    'gIR': 0,
    'gLUM': 0,
    'gUV': null,
    'pIDar': 0,
    'aUT': 0,
    'ax': 424,
    'ay': 15904,
    'az': 2320,
    'gx': -434,
    'gy': 82,
    'gz': -67,
    'accTemp': 30.04,
  },
  'filename': 'coloc-feb-23-2023-pre/2023/3/23/10/undefined/1679566847237_undefined',
}];
